import { isMobile } from '../utils/index'
export default {
  address: '新北市新店區安興路105號10樓之7',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.6370875267094!2d121.51260381524632!3d24.978459146669085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34680215227748c9%3A0x848e4a39fe46e8fd!2zMjMx5paw5YyX5biC5paw5bqX5Y2A5a6J6IiI6LevMTA16Jmf!5e0!3m2!1szh-TW!2stw!4v1611130031918!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/kzjXuViJCCoZ7L2Y9',
  phone: 'T:(02)2211-3513　F:(02)2211-3875',
  email: '@gmail.com',
  mechanism: '得邦廣告',
  fbLink: 'https://www.facebook.com/得邦廣告-1938941179701767',
  fbMessage: 'https://m.me/1938941179701767',
  caseName: '得邦廣告',
  indigatorLength: 10,

  houseInfos: [
  //  ['投資興建', '合康建設股份有限公司'],
   // ['營造公司', '閤康聯合建築師事務所'],
   // ['營造公司', '洛城營造'],
   // ['企劃銷售', '新高創廣告有限公司'],
   //['景觀設計', '六國景觀-蘇瑞泉大師'],
   // ['建照號碼', '108建字第0256號'],
  ],

  gtmCode: ['5QH7TTP'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lep-78UAAAAAIDMeDQOBwK-OK3yNooejpZeNCsS',
  order: {
    title: isMobile ? '聯絡我們' : '聯絡我們',
    subTitle: 'Contacts'
  }
}
