export default [
  { name: '首頁', subTitle: 'Home', path: '#section1'},
  { name: '關於得邦', subTitle: 'About Us', path: 'about/#top'},
  { name: '熱銷建案', subTitle: 'Projects', path: 'hot/#top'},
  { name: '經典建案', subTitle: 'Classic', path: 'products/#top'},
  { name: '房產資訊', subTitle: 'News', path: 'news/#top'},
  { name: '影音專區', subTitle: 'Video', path: 'videos/#top'},
  { name: '聯絡我們', subTitle: 'Contact Us', path: 'contacts'},
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0 },
]
